import React from 'react';
import Image from 'next/image';

interface Props {
  children?: any;
}

const SessionFormContainer: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <div className="flex md:items-stretch md:flex-1 ">
        <div
          className="
            self-center px-4 w-full form-background
            md:mx-auto md:w-auto
            xl:mx-auto xl:w-auto
          "
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default SessionFormContainer;
