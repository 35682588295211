import React from 'react';
import Image from 'next/image';

interface Props {}

const SignInPlaceholder: React.FC = ({}: Props) => {
  return (
    <>
      <div className="flex md:items-stretch md:flex-1 bg-royal-blue">
        <div
          className="
              absolute aspect-[347/330] mx-auto block
              w-[175px] mt-[-10px]
              xl:w-[330px] xl:mt-0
            "
        >
          <Image
            src="/images/signin-left-top-corner-image.png"
            alt={''}
            fill
            className="object-cover"
          />
        </div>
        <div className="mx-auto self-center">
          <h1
            className="
                mx-auto font-primary text-center text-white
                text-30 w-[330px] mt-[60px]
                md:text-48 md:w-[540px]
                xl:mt-20
              "
          >
            Sign in to access your Selfmade benefits.
          </h1>
          <h4
            className="
                mx-auto font-secondary text-center text-white
                w-[335px] mb-[20px]
                md:text-16 md:w-[450px]
                xl:mt-5 xl:mb-0
              "
          >
            Our community, courses, coaches, and more are here for you!
          </h4>

          <div
            className="
                relative mx-auto aspect-[26/33]
                hidden mt-[30px]
                xl:block xl:mt-[-66px] w-[33px] xl:mr-[98px]
              "
          >
            <Image
              className="mx-auto object-cover"
              src="/images/signin-small-vector.png"
              alt={''}
              fill
            />
          </div>

          <div
            className="
                relative aspect-[800/538] mx-auto block
                mb-[30px]
                md:w-[664px]
                xl:mb-0 xl:mt-[95px] xl:w-[690px]
              "
          >
            <Image
              className="object-cover"
              src="/images/signin-illustration.png"
              alt={''}
              fill
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPlaceholder;
